import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img alt="dfd" src={require("./public/logo.png")} />{` `}</p>
    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`Dragnet is an affordable and simple intrusion detection system.  It uses clever techniques to create honey pots that automatically alert the security personnel you designate if the honey pot is ever accessed. We call these honey pots security tokens.`}</p>
    <p>{`There is no additional software to install to make Dragnet work.  Just create and download the Dragnet security tokens you want and deploy them where you need.`}</p>
    <p>{`No machine learning, no artificial intelligence.  Just tried and true techniques to shorten your time to detection and kick bad actors out before they cause damage.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      